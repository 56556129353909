import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Icon, Text, LinkBox, Link, Box, Section } from "@quarkly/widgets";
import { FaSkiing } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
const defaultProps = {
	"padding": "70px 0 70px 0",
	"sm-padding": "40px 0 40px 0",
	"quarkly-title": "Footer-21"
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "18px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0",
			"md-margin": "0px 0 0px 0",
			"text-align": "left",
			"font": "--headline3",
			"sm-margin": "0px 0 0px 0",
			"display": "block",
			"children": "Tróia Golf Club"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-justify-content": "center",
			"md-align-items": "center",
			"justify-content": "center",
			"align-items": "center",
			"margin": "20px 0px 50px 0px",
			"flex-wrap": "wrap",
			"sm-margin": "20px 0px 36px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--darkL2",
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 0px",
			"md-margin": "0px 6px 0px 6px",
			"href": "/index",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "Principal"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsDot,
			"size": "24px",
			"color": "#b2b8be"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 6px",
			"href": "/about",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "Sobre nós"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsDot,
			"size": "24px",
			"color": "#b2b8be"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 6px",
			"href": "/contacts",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "Contate-nos"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" align-items="center" />
		<LinkBox {...override("linkBox")}>
			<Text {...override("text")} />
		</LinkBox>
		<Box {...override("box")}>
			<Link {...override("link")} />
			<Icon {...override("icon1")} />
			<Link {...override("link1")} />
			<Icon {...override("icon2")} />
			<Link {...override("link2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;